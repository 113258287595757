import { Link } from "gatsby";
import React from "react";
import Heading from "../../components/Heading";

const LooseFx = ({ parentHeading }) => {
  return (
    <article id="loose-fx" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={parentHeading}>LooseFx</Heading>
      </div>
      <p>
        In corporate Foreign Exchange customers can choose to hedge their
        currency requirements against rate moves or aim for out-performance of
        the market.
      </p>

      <p>
        LooseFX aims to give customers a better view of their FX position
        through scenario analysis and invoice planning.
      </p>

      <p>
        <Link to="/introducing-loosefx">Read more about LooseFx</Link>{" "}
      </p>
    </article>
  );
};

LooseFx.key = "LooseFx";

export default LooseFx;
