import React from "react";
import Heading from "../../components/Heading";

const BeBroadband = ({ parentHeading }) => {
  return (
    <article id="BeBroadband" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={parentHeading}>Be</Heading>
        <Heading
          parentLevel={parentHeading + 1}
          className="subHeading"
          role="doc-subtitle"
        >
          2009
        </Heading>
      </div>
      <figure>
        <img
          loading="lazy"
          className="alignnone size-full wp-image-188"
          src="/uploads/be-broadband.png"
          alt=""
          width="676"
          height="390"
          srcSet="/uploads/be-broadband.png 676w, /uploads/be-broadband-480x277.png 480w"
          sizes="(max-width: 676px) 100vw, 676px"
        />
      </figure>
      <p></p>
      <p>
        <a href="http://www.theregister.co.uk/2009/07/23/bethere_redesign_horror/">
          What a reaction!
        </a>{" "}
        Am I mad to put it on here? Yeah probably, the HTML got mashed by the
        CMS and they changed the colours soon after launch. But without putting
        it on we’d be missing 6 weeks of my life…
      </p>
    </article>
  );
};

BeBroadband.key = "BeBroadband";

export default BeBroadband;
