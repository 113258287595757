import { Link } from "gatsby";
import React from "react";
import Heading from "../../components/Heading";

const LooseChange = ({ parentHeading }) => {
  return (
    <article id="loose-change" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={parentHeading}>LooseChange</Heading>
      </div>

      <div className="galleryItemImage">
        <img src="/uploads/loosechange-2.png" alt="" />
      </div>

      <p>
        LooseChange is your private way to analyse your finances. Export the
        transactions from your bank account and into the app to categorise and
        compare your outgoings.
      </p>

      <p>
        Your information is stored encrypted on your device, giving you full
        control over its storage and deletion.
      </p>

      <p>
        <Link to="/introducing-loosechange">Read more about LooseChange</Link>{" "}
      </p>
    </article>
  );
};

LooseChange.key = "LooseChange";

export default LooseChange;
