import React from "react";
import Heading from "../../components/Heading";

const McDonalds = () => {
  return (
    <article id="mcdonalds" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={1}>Little Tasters – McDonalds</Heading>
        <Heading parentLevel={2}>2013</Heading>
      </div>
      <figure>
        <img
          loading="lazy"
          className="alignnone size-full wp-image-197"
          src="/uploads/mcdonalds-little-tasters.jpg"
          alt=""
          width="676"
          height="390"
          srcSet="/uploads/mcdonalds-little-tasters.jpg 676w, /uploads/mcdonalds-little-tasters-480x277.jpg 480w"
          sizes="(max-width: 676px) 100vw, 676px"
        />
      </figure>
      <p>
        Short term assignment to implement a content page with a custom style
        inside of a CMS system. Also integrates with the existing nutritional
        calculator.
      </p>
    </article>
  );
};

McDonalds.key = "McDonalds";

export default McDonalds;
