import React from "react";
import EntryContent from "../EntryContent";
import Title from "../Title";

const PageFull = ({ title, content, children = null }) => {
  return (
    <article
      className="post type-post format-standard hentry"
      itemScope=""
      itemProp="blogPost"
      itemType="http://schema.org/BlogPosting"
    >
      <header className="hentry__header">
        <h1 className="entry-title" itemProp="headline">
          <Title title={title} />
        </h1>
      </header>
      <EntryContent itemProp="articleBody">
        {Boolean(content) ? (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content.rendered }}
          />
        ) : (
          children
        )}
      </EntryContent>
      <footer className="hentry__footer" />
    </article>
  );
};

export { PageFull };
