import React from "react";
import Heading from "../../components/Heading";

const CocaCola = ({ parentHeading }) => {
  return (
    <article id="coca-cola" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={parentHeading}>Future Flames – Coca Cola</Heading>
        <Heading
          parentLevel={parentHeading + 1}
          className="subHeading"
          role="doc-subtitle"
        >
          2011
        </Heading>
      </div>
      <figure>
        <img
          loading="lazy"
          className="alignnone size-full wp-image-192"
          src="/uploads/coke-future-flame.png"
          alt=""
          width="676"
          height="390"
          srcSet="/uploads/coke-future-flame.png 676w, /uploads/coke-future-flame-480x277.png 480w"
          sizes="(max-width: 676px) 100vw, 676px"
        />
      </figure>
      <p>
        The aim of the site is to recognise those people who make a difference
        here in the UK and to give them the chance to carry the Olympic Torch in
        their area. Totally AJAX driven, integrated with the CokeZone
        environment. Not perfect but it’s always great to work for a big brand
        like Coca Cola.
      </p>
    </article>
  );
};

CocaCola.key = "CocaCola";

export default CocaCola;
