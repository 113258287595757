import React from "react";
import Heading from "../../components/Heading";

const MediaPlayer = ({ parentHeading }) => {
  return (
    <article id="media-player" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={parentHeading}>
          Media player for large supermarket chain
        </Heading>
        <Heading
          parentLevel={parentHeading + 1}
          className="subHeading"
          role="doc-subtitle"
        >
          2008 into 2009
        </Heading>
      </div>
      <p>
        This unreleased project was using web technologies to build a desktop
        media player, integrated with their online store. Built using XUL,
        JavaScript, HTML and CSS.
      </p>
    </article>
  );
};

MediaPlayer.key = "MediaPlayer";

export default MediaPlayer;
