import React from "react";
import Heading from "../../../components/Heading";
import Gallery from "../../../components/Gallery";
import galleryData from "../../gallery-content/mclaren.json";

const McLaren = ({ parentHeading }) => {
  return (
    <>
      <article id="mclaren" className="galleryItem">
        <div className="headings">
          <Heading parentLevel={parentHeading}>McLaren Automotive</Heading>
          <Heading
            parentLevel={parentHeading + 1}
            className="subHeading"
            role="doc-subtitle"
          >
            2013
          </Heading>
        </div>
        <img
          loading="lazy"
          className="alignnone size-full wp-image-191"
          src="/uploads/cars-mclaren.jpg"
          alt=""
          width="676"
          height="390"
          srcSet="/uploads/cars-mclaren.jpg 676w, /uploads/cars-mclaren-480x277.jpg 480w"
          sizes="(max-width: 676px) 100vw, 676px"
        />
        <p>
          The McLaren Automotive website contains a number of complex
          interactive modules in a responsive framework, all CMS driven.
        </p>
        <p>
          Highlights include a Customiser with introductory video, a gallery
          layer populated through JSON, interior explorer utilising WebGL via
          three.js
          <br />
          and a number of slideshows controlled by user interaction such as
          scrolling or on screen controls.
        </p>
      </article>

      <Gallery columns={3} galleryData={galleryData} />
    </>
  );
};

McLaren.key = "McLaren";

export default McLaren;
