import React from "react";
import Heading from "../../components/Heading";

const AvisBudget = ({ parentHeading }) => {
  return (
    <article id="avis-budget" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={parentHeading}>Avis Budget Group</Heading>
        <Heading
          parentLevel={parentHeading + 1}
          className="subHeading"
          role="doc-subtitle"
        >
          2014
        </Heading>
      </div>
      <img
        loading="lazy"
        className="alignnone size-full wp-image-187"
        src="/uploads/avis.jpg"
        alt=""
        width="676"
        height="390"
        srcSet="/uploads/avis.jpg 676w, /uploads/avis-480x277.jpg 480w"
        sizes="(max-width: 676px) 100vw, 676px"
      />
      <p>
        Implemented a redesign of the Avis and Budget car rental sites. Both
        sites are heavily adaptive. The booking journey was a significant
        challenge as it was, effectively, a single page web-application shared
        for each brand. Each stage was created as a component and they all
        shared an API for communication with the central controller. Avis.co.uk
        is live and, at the time of writing, Budget is being integrated by an
        offshore development team.
      </p>
    </article>
  );
};

AvisBudget.key = "AvisBudget";

export default AvisBudget;
