import React from "react";
import Heading from "../../components/Heading";

const VodaCMS = ({ parentHeading }) => {
  return (
    <article id="voda-cms" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={parentHeading}>
          Vodafone UK CMS and Styleguide implementation
        </Heading>
        <Heading
          parentLevel={parentHeading + 1}
          className="subHeading"
          role="doc-subtitle"
        >
          2010
        </Heading>
      </div>
      <p>
        Finally a chance to start afresh on the Vodafone site, my swan song on
        the account was to lead the creation of the front-end code for this new
        style. On top of the HTML and CSS there are custom jQuery plugins (using
        OO patterns where appropriate) and lazy loading where possible.
      </p>
    </article>
  );
};

VodaCMS.key = "VodaCMS";

export default VodaCMS;
