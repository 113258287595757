import React from "react";
import Heading from "../components/Heading";
import Layout from "../components/Layout";
import PageFull from "../components/PageFull";
import {
  PRIMARY_CONTENT,
  AGENCY_CONTENT
} from "../page-content/portfolio-items";

import "./portfolio.scss";

const Portfolio = () => {
  return (
    <Layout description="A selection of my previous work both personal and professional.">
      <PageFull title={{ rendered: "Portfolio" }}>
        {PRIMARY_CONTENT.map((Component) => (
          <Component key={Component.key} parentHeading={1} />
        ))}
        <hr />
        <Heading parentHeading={1}>Agency work</Heading>
        {AGENCY_CONTENT.map((Component) => (
          <Component key={Component.key} parentHeading={2} />
        ))}
      </PageFull>
    </Layout>
  );
};

export default Portfolio;
