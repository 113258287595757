import React from "react";
import Heading from "../../components/Heading";

const Unfold = ({ parentHeading }) => {
  return (
    <article id="unfold" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={parentHeading}>Unfold</Heading>
      </div>
      <div className="galleryItemImage">
        <img src="/uploads/unfold-1-be-inspired.png" alt="" />
      </div>
      <p>
        Part of Y Combinator's Startup School 2018, Unfold's mission is to
        inspire people to do great things through storytelling.
      </p>
      <p>
        Our micro-biography approach provides a platform to pass on the legacy
        of our society through the stories of the men and women that made it.
      </p>

      <p>
        <a href="https://play.google.com/store/apps/details?id=bio.unfold.unfold&amp;hl=en_GB&amp;gl=US">
          View the Unfold App
        </a>{" "}
      </p>
    </article>
  );
};

Unfold.key = "Unfold";

export default Unfold;
