import AvisBudget from "./avis-budget";
import McLaren from "./mclaren";
import GeekWeek from "./geek-week";
import McDonalds from "./mcdonalds";
import Hellmanns from "./hellmanns";
import RecipeSite from "./recipe-site";
import Pfm from "./pfm";
import VodaCMS from "./vodafone-cms";
import VodafoneRefresh from "./vodafone-refresh";
import BeBroadband from "./be-broadband";
import MediaPlayer from "./media-player";
import VodaEcare from "./vodafone-ecare";
import VodaShop from "./vodafone-shop";
import CocaCola from "./coca-cola";
import LooseChange from "./loose-change";
import LooseFx from "./loose-fx";
import Unfold from "./unfold";

const PRIMARY_CONTENT = [LooseChange, LooseFx, Unfold];

const AGENCY_CONTENT = [
  AvisBudget,
  McLaren,
  GeekWeek,
  McDonalds,
  Hellmanns,
  RecipeSite,
  CocaCola,
  Pfm,
  VodaCMS,
  VodafoneRefresh,
  BeBroadband,
  MediaPlayer,
  VodaEcare,
  VodaShop
];

export { PRIMARY_CONTENT, AGENCY_CONTENT };
