import React from "react";
import Heading from "../../components/Heading";

const GeekWeek = ({ parentHeading }) => {
  return (
    <article id="geekweek" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={parentHeading}>
          Geekweek Mobile Quiz – YouTube
        </Heading>
        <Heading
          parentLevel={parentHeading + 1}
          className="subHeading"
          role="doc-subtitle"
        >
          2013
        </Heading>
      </div>
      <p>
        Responsive design HTML5 game. Utilised JavaScript with canvas and HTML5
        audio to deliver a set of questions in a set time for a range of mobile
        devices including Blackberry, Android 2.3+, Windows Phone and iOS.
      </p>
    </article>
  );
};

GeekWeek.key = "GeekWeek";

export default GeekWeek;
