import React from "react";
import Heading from "../../../components/Heading";
import Gallery from "../../../components/Gallery";
import galleryData from "../../gallery-content/pfm.json";

const Pfm = ({ parentHeading }) => {
  return (
    <>
      <article id="pfm" className="galleryItem">
        <div className="headings">
          <Heading parentLevel={parentHeading}>
            Personal Financial Manager for a Price Comparison site
          </Heading>
          <Heading
            parentLevel={parentHeading + 1}
            className="subHeading"
            role="doc-subtitle"
          >
            2011
          </Heading>
        </div>
        <figure>
          <img
            loading="lazy"
            className="alignnone size-full wp-image-198"
            src="/uploads/pfm.jpg"
            alt=""
            width="676"
            height="390"
            srcSet="/uploads/pfm.jpg 676w, /uploads/pfm-480x277.jpg 480w"
            sizes="(max-width: 676px) 100vw, 676px"
          />
        </figure>
        <p></p>
        <p>
          My first opportunity to use HTML5, this unreleased RIA involved a
          large amount of CSS3 and Canvas/SVG using Raphaël. I used event-driven
          programming to allow the complex visualisations and wider UI to react
          to state changes. All data was passed as JSON.
        </p>
      </article>

      <Gallery columns={3} galleryData={galleryData} />
    </>
  );
};

Pfm.key = "Pfm";

export default Pfm;
