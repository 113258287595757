import React from "react";
import Heading from "../../components/Heading";

const Hellmanns = () => {
  return (
    <article id="hellmanns" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={1}>Hellmann’s</Heading>
        <Heading parentLevel={2}>2012</Heading>
      </div>
      <figure>
        <img
          loading="lazy"
          className="alignnone size-full wp-image-194"
          src="/uploads/hellmanns.jpg"
          alt=""
          width="676"
          height="390"
          srcSet="/uploads/hellmanns.jpg 676w, /uploads/hellmanns-480x277.jpg 480w"
          sizes="(max-width: 676px) 100vw, 676px"
        />
      </figure>

      <p>
        Front-end build with CMS integration. Whilst primarily a simple site it
        had to support heavy localisation of content and style.
      </p>
    </article>
  );
};

Hellmanns.key = "Hellmanns";

export default Hellmanns;
