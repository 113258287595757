import React from "react";
import Heading from "../../components/Heading";

const VodaShop = ({ parentHeading }) => {
  return (
    <article id="voda-shop" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={parentHeading}>Vodafone UK shop</Heading>
        <Heading
          parentLevel={parentHeading + 1}
          className="subHeading"
          role="doc-subtitle"
        >
          2008
        </Heading>
      </div>
      <figure>
        <img
          loading="lazy"
          className="alignnone size-full wp-image-205"
          src="/uploads/vodafone-shop.png"
          alt=""
          width="676"
          height="390"
          srcSet="/uploads/vodafone-shop.png 676w, /uploads/vodafone-shop-480x277.png 480w"
          sizes="(max-width: 676px) 100vw, 676px"
        />
      </figure>
    </article>
  );
};

VodaShop.key = "VodaShop";

export default VodaShop;
