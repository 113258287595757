import React from "react";
import Heading from "../../components/Heading";

const VodafoneRefresh = ({ parentHeading }) => {
  return (
    <article id="vfrefresh" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={parentHeading}>Vodafone UK Style Refresh</Heading>
        <Heading
          parentLevel={parentHeading + 1}
          className="subHeading"
          role="doc-subtitle"
        >
          2014
        </Heading>
      </div>
      <figure>
        <img
          loading="lazy"
          className="alignnone size-full wp-image-204"
          src="/uploads/vodafone-rebrand.png"
          alt=""
          width="676"
          height="390"
          srcSet="/uploads/vodafone-rebrand.png 676w, /uploads/vodafone-rebrand-480x277.png 480w"
          sizes="(max-width: 676px) 100vw, 676px"
        />
      </figure>
      <p></p>
      <p>
        For this project I was coordinating and troubleshooting the integration
        of the new Vodafone online branding onto 17 of their domains. My aim was
        to apply the styles with as few changes to the HTML of existing sites as
        possible, doing lots of work through the CSS to make up for the
        inconsistency in the markup.
      </p>
    </article>
  );
};

VodafoneRefresh.key = "VodafoneRefresh";

export default VodafoneRefresh;
