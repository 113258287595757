import React from "react";

function Heading({ level, parentLevel = 0, className = "", role, children }) {
  const thisLevel = level || parentLevel + 1;
  const Tag = `h${thisLevel}`;

  return (
    <Tag className={className} role={role}>
      {children}
    </Tag>
  );
}

export { Heading };
