import React from "react";
import Heading from "../../components/Heading";

const VodaEcare = ({ parentHeading }) => {
  return (
    <article id="voda-ecare" className="galleryItem">
      <div className="headings">
        <Heading parentLevel={parentHeading}>
          Vodafone UK eCare (My account area)
        </Heading>
        <Heading
          parentLevel={parentHeading + 1}
          className="subHeading"
          role="doc-subtitle"
        >
          2008
        </Heading>
      </div>
      <figure>
        <img
          loading="lazy"
          className="alignnone size-full wp-image-203"
          src="/uploads/vodafone-eCare.png"
          alt=""
          width="676"
          height="390"
          srcSet="/uploads/vodafone-eCare.png 676w, /uploads/vodafone-eCare-480x277.png 480w"
          sizes="(max-width: 676px) 100vw, 676px"
        />
      </figure>
    </article>
  );
};

VodaEcare.key = "VodaEcare";

export default VodaEcare;
