import React from "react";

import "./Gallery.scss";

const GalleryItem = ({ datum }) => {
  const { width, height, alt, srcs, sizes } = datum;

  const srcSet = sizes.reduce((acc, size, index) => {
    const separator = index === 0 ? "" : ",";
    return `${acc}${separator} ${srcs[index]} ${size}`;
  }, "");

  const largestImage = srcs[srcs.length - 1];
  const sizesValue = sizes[0].replace("w", "px");

  return (
    <li className="blocks-gallery-item">
      <figure>
        <a href={largestImage}>
          <img
            loading="lazy"
            width={width}
            height={height}
            src={srcs[0]}
            alt={alt}
            srcSet={srcSet}
            sizes={`(max-width: ${sizesValue}) 100vw,  ${sizesValue}`}
          />
        </a>
      </figure>
    </li>
  );
};

const Gallery = ({ children, columns, galleryData }) => {
  if (!galleryData) {
    return null;
  }

  return (
    <div>
      <ul className={`wp-block-gallery columns-${columns} is-cropped`}>
        {galleryData.items.map((galleryItem, index) => (
          <GalleryItem key={`${galleryData.id}-${index}`} datum={galleryItem} />
        ))}
        {children}
      </ul>
    </div>
  );
};

export default Gallery;
