import React from "react";
import Heading from "../../../components/Heading";
import Gallery from "../../../components/Gallery";
import galleryData from "../../gallery-content/recipe-site.json";

const RecipeSite = ({ parentHeading }) => {
  return (
    <>
      <article id="recipe-site" className="galleryItem">
        <div className="headings">
          <Heading parentLevel={parentHeading}>Unreleased Recipe Site</Heading>
          <Heading
            parentLevel={parentHeading + 1}
            className="subHeading"
            role="doc-subtitle"
          >
            2011 / 2012
          </Heading>
        </div>
        <figure>
          <img
            loading="lazy"
            className="alignnone size-full wp-image-200"
            src="/uploads/recipes.jpg"
            alt=""
            width="676"
            height="390"
            srcSet="/uploads/recipes.jpg 676w, /uploads/recipes-480x277.jpg 480w"
            sizes="(max-width: 676px) 100vw, 676px"
          />
        </figure>
        <p>
          This recipe website has an ‘app inspired’ design with transitions
          between pages and with supporting and additional content surfaced
          through panels.
        </p>
        <p>
          It included a full screen mode to take you through the steps of a
          recipe and utilised local storage to aid performance.
        </p>
      </article>

      <Gallery columns={3} galleryData={galleryData} />
    </>
  );
};

RecipeSite.key = "RecipeSite";

export default RecipeSite;
